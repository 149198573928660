import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/nightshift/logo-color-no-bg-tiny.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
        <img src={imgL1Logo} alt="" />
    </Link>
  );
};

export default Logo;
