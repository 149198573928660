export const menuItems = [
  {
    name: "/",
    label: "Home",
  },
  {
    name: "about/",
    label: "Story",
  },
  {
    name: "coming-soon/",
    label: "Projects",
  },
  {
    name: "services/",
    label: "Services",
  },
    {
    name: "blog/",
    label: "Blog",
  },
];
